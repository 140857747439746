'use client';

import { useEffect, useState } from 'react';
import { GlobalFooter } from '@motortrend/ids';
import { nitrous } from '@motortrend/nitrous-web-sdk';
import DOMPurify from 'isomorphic-dompurify';

import {
  EuCountryCodes,
  extendedNoticeStates,
} from '@/utils/onetrust/constants';

import { type GlobalFooterWrapperProps } from './GlobalFooterWrapper.props';

const handleReportIssue = (data: string) => {
  const sanitizedData = DOMPurify.sanitize(data);
  nitrous.events.logReportIssue(sanitizedData);
};

const GlobalFooterWrapper = (props: GlobalFooterWrapperProps) => {
  const { links = [], linksText, ...propsLeftover } = props;

  const [updatedLinks, setUpdatedLinks] = useState(links);
  const [geoData, setGeoData] = useState({
    continent: '',
    country: '',
    state: '',
    stateName: '',
  });

  useEffect(() => {
    // We are using this __otccpaooLocation variable here because
    // OneTrust.getGeolocationData is inconsistent. This variable is set by the
    // https://cdn.cookielaw.org/opt-out/otCCPAiab.js script
    const fetchedGeoData = window.__otccpaooLocation;
    if (fetchedGeoData) {
      setGeoData(fetchedGeoData);
    }
  }, []);

  useEffect(() => {
    const _links = links.map((link) => {
      if (link.id === 'cookieSettings') {
        if (
          geoData?.country?.toUpperCase() === 'US' &&
          extendedNoticeStates.includes(geoData?.state?.toUpperCase())
        ) {
          link.title = linksText.OneTrustUSAlt;
        } else if (
          EuCountryCodes.includes(geoData?.country?.toUpperCase()) ||
          geoData.continent.toUpperCase() === 'EU'
        ) {
          link.title = linksText.OneTrustEU;
        } else {
          link.title = linksText.OneTrust;
        }
      }
      return link;
    });

    setUpdatedLinks(_links);
  }, [geoData, links, linksText]);

  return (
    <GlobalFooter
      className="relative"
      links={updatedLinks}
      reportIssue={handleReportIssue}
      {...propsLeftover}
    />
  );
};

export default GlobalFooterWrapper;
